<template>
  <div>
    <v-sheet
      class="mx-3 mb-6"
      width="300"
    >
      <v-skeleton-loader
        v-if="isLoading"
        class="mx-auto elevation-1"
        type="card-heading, list-item-three-line, actions"
        max-width="300"
      >
      </v-skeleton-loader>
      <v-card
        v-else
        class="mx-auto"
      >
        <v-system-bar class="py-5">
          <h3>{{ system.name }}</h3>

          <v-spacer></v-spacer>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="onlineIconColor"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ onlineIcon }}
                  </v-icon>
            </template>
            <span>{{ onlineToolTipText }}</span>
          </v-tooltip>
        </v-system-bar>

        <v-container>
          <v-row>
            <v-col sm="5">
              <span class="font-weight-medium mb-0">{{ $t('systems.card.status-label') }}</span>
            </v-col>
            <v-col sm="7">
              <span v-if="status && status.length > 0">
                {{ status }}
              </span>
              <span v-else>
                -
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <span class="font-weight-medium mb-0">{{ $t('systems.card.last-used-label') }}</span>
            </v-col>
            <v-col sm="7">
              <DateTimeDisplay :date="latestSession ? latestSession.startTime : null"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <span class="font-weight-medium mb-0">{{ $t('systems.card.software-label') }}</span>
            </v-col>
            <v-col sm="7">
              <SoftwareVersionDisplay :softwareVersion="softwareVersion"/>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>

          <router-link :to="detailsUrl">
            <v-btn
              icon
              tile
              large
              color="primary"
            >
              <v-icon>mdi-open-in-app</v-icon>
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import DateTimeDisplay from '@/components/DateTimeDisplay'
import SoftwareVersionDisplay from '@/components/SoftwareVersionDisplay'

export default {
  props: {
    systemId: { type: Number, required: true },
    isOnline: { type: Boolean, default: null },
    status: { type: String, default: null }
  },
  components: { DateTimeDisplay, SoftwareVersionDisplay },
  data () {
    return {
      system: null,
      softwareVersion: null,
      latestSession: null,
      isLoading: true
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    fetchSystem () {
      const requests = [
        axios.get(`systems/${this.systemId}`),
        axios.get(`systems/${this.systemId}/softwareVersion`),
        axios.get(`systems/${this.systemId}/latestSession`),
        // TODO: remove this when the systems overview is completed.
        new Promise(resolve => setTimeout(resolve, Math.floor(Math.random() * 2000) + 1000))
      ]

      axios
        .all(requests)
        .then(axios.spread((...responses) => {
          this.system = responses[0].data
          this.softwareVersion = responses[1].data ? responses[1].data : null
          this.latestSession = responses[2].data
        }))
        .catch(axios.spread((...manyErrors) => {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('errors.loading-system-failed')],
            duration: 5000
          })
        }))
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  computed: {
    detailsUrl () {
      return `/systems/${this.system.id}`
    },
    onlineIcon () {
      if (this.isOnline) return 'mdi-circle'
      if (this.isOnline === false) return 'mdi-circle'
      else return 'mdi-circle'
    },
    onlineIconColor () {
      if (this.isOnline) return 'success'
      if (this.isOnline === false) return 'error'
      else return 'gray'
    },
    onlineToolTipText () {
      if (this.isOnline) return this.$t('systems.card.online-tooltip.online')
      if (this.isOnline === false) return this.$t('systems.card.online-tooltip.offline')
      else return this.$t('systems.card.online-tooltip.unknown')
    }
  },
  mounted () {
    this.fetchSystem()
  }
}
</script>
